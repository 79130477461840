.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
}

.sidebar {
    position: fixed;
    top: 130px;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 180px;
    height: $sidebar-height;
    background: $brand-background;

    .caret{
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    &[data-background-color="black"]{
        background-color: #191919;
    }
    
    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 130px);
        // overflow: auto;
        width: 180px;
        z-index: 4;

        padding-bottom: 30px;

        .dropdown .dropdown-backdrop{
            display: none !important;
        }

        .navbar-form{
            border: none;
            box-shadow: none;

          .input-group {
            font-size: 1.7em;
            height: 36px;
            width: 78%;
            padding-left: 17px;
          }
        }

        > .nav,
        .user .user-info{
            [data-toggle="collapse"] ~ div > ul > li > a{
                span{
                    display: inline-block;
                    @extend .animation-transition-general;
                }

                .sidebar-normal{
                    margin: 0;
                    position: relative;
                    transform: translateX(0px);
                    opacity: 1;
                    white-space: nowrap;
                    display: block;

                }

                .sidebar-mini{
                    text-transform: uppercase;
                    width: 30px;
                    margin-right: 15px;
                    text-align: center;
                    letter-spacing: 1px;
                    position: relative;
                    float: left;
                    display: inherit;
                }

                i{
                    font-size: 17px;
                    line-height: 20px;
                    width: 26px;
                }
            }
        }
    }

    .nav{
        display: block;

        .caret{
            margin-top: 13px;
            position: absolute;
            right: 6px;
        }

        li{
            > a{
              &:hover,
              &:focus{
                  background-color: transparent;
                  outline: none;
              }
            }

            &:first-child > a{
                margin: 0 15px;
            }

            &:hover > a,
            & .dropdown-menu a:hover,
            & .dropdown-menu a:focus,
            &.active > [data-toggle="collapse"]{
                color: $black-color;
                box-shadow: none;
            }

            &.active > [data-toggle="collapse"]{
                i{
                    color: $brand-primary;
                }
            }

            &.active > a,
            &.active > a i{
                color: $icon-color;
                .icon-product {
                    background: url("/assets/svg/product-active.svg");
                }
                .icon-pot {
                    background: url("/assets/svg/pot-active.svg");
                }
                .icon-users {
                    background: url("/assets/svg/users-active.svg");
                }
                .icon-notifications {
                    background: url("/assets/svg/notification-active.svg");
                }
            }

            &.separator{
                margin: 15px 0;

                &:after{
                    width: calc(100% - 30px);
                    content: "";
                    position: absolute;
                    height: 1px;
                    left: 15px;
                    background-color: $brand-accent;
                }

                & + li {
                    margin-top: 31px;
                }
            }
        }

        p{
            margin: 15px 0 0 0;
            line-height: 14px;
            font-size: 12px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            text-align: center;
            font-weight: 400;
            letter-spacing: 0px;
            color: $brand-search-text-label;
        }

        i{
            font-size: 20px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            color: $brand-primary;
        }
    }

    .nav li a,
    .nav li .dropdown-menu a{
          margin: 10px 15px 0;
          border-radius: $border-radius-base;
          color: $black-color;
          text-transform: capitalize;
          font-size: $font-paragraph - 1;
          text-align: center;
          padding: 10px 15px;
    }
    .nav li a {
        padding: 10px 15px;
    }


    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: .93;
        }
    }

    &[data-image]:after,
    &.has-image:after{
        opacity: .77;
    }
}

.off-canvas-sidebar .navbar-collapse{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
            margin: 0 15px;
        }

        > li > a:focus,
        > li > a:hover{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}
.header-panel{
    position: sticky;
    width: $sidebar-width;
    z-index: 1000;
}


.main-panel{
    position: relative;
    float: right;
    top: 130px;
    width: $sidebar-width;
    background-color: $brand-background;
    z-index: 0;
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

     .main-content{
        padding: 0 15px 0 0;
        min-height: calc(100vh - 180px);
    }

     .navbar{
        margin-bottom: 0;
    }

    .header{
        margin-bottom: 30px;

        .title{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.perfect-scrollbar-on{
    .main-panel{
        height: $sidebar-height;
        max-height: $sidebar-height;
        // z-index: -1;
    }
}


.main-panel{
    -webkit-transition-property: top,bottom,width;
    transition-property: top,bottom, width;
    -webkit-transition-duration: .2s,.2s, .35s;
    transition-duration: .2s,.2s, .35s;
    -webkit-transition-timing-function: linear,linear,ease;
    transition-timing-function: linear,linear,ease;
    -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

@media (min-width: 991px) {
    .sidebar-mini{
        .visible-on-sidebar-regular{
            display: none !important;
        }
        .visible-on-sidebar-mini{
            display: inline-block !important;
        }

        .sidebar,
        .sidebar .sidebar-wrapper{
            width: 80px;
        }

        .main-panel{
            width: $sidebar-mini-width;
        }

        .sidebar{
            display: block;
            font-weight: 200;
            z-index: 9999;

            .logo{
                a.logo-normal{
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
            }

            .sidebar-wrapper{
                > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span,
                > .nav li > a p{
                    @include transform-translate-x(-25px);
                    opacity: 0;
                }
            }
        }

        .sidebar:hover{
            width: 180px;

            .logo{
                a.logo-normal{
                    opacity: 1;
                    @include transform-translate-x(0px);
                }
            }

            .sidebar-wrapper{
                width: 180px;

                > .nav li > a p,
                > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span{
                    @include transform-translate-x(0px);
                    opacity: 1;
                }
            }
        }
    }

    .sidebar {
      .nav .nav-item {
        &.active-pro {
          position: absolute;
          width: 100%;
          bottom: 13px;
        }
      }
    }
}

.sidebar-icons {
    padding-top: 35% !important;
    background-position: top !important;
    background-size: 42% !important;
    background-repeat: no-repeat !important;
}

.icon-product {
    background-image: url('/assets/svg/product.svg');
}
.icon-pot {
    background-image: url('/assets/svg/pot.svg');
}
.icon-users {
    background-image: url('/assets/svg/user.svg');
}
.icon-notifications {
    background-image: url('/assets/svg/notification.svg');
}

.icon-product:hover {
    background: url("/assets/svg/product-active.svg");
}
.icon-pot:hover {
    background: url("/assets/svg/pot-active.svg");
}
.icon-users:hover {
    background: url("/assets/svg/users-active.svg");
}
.icon-notifications:hover {
    background: url("/assets/svg/notification-active.svg");
}


