html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
//   font-family: $font-family-sans-serif;
//   font-weight: 300;
//   line-height: 1.5em;
// }
body {
  margin: 0;
  font-family:  $font-family-sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $quixant-grey-text;
  background-color: #1F1E1E;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

// h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
//   margin-top: 0;
//   margin-bottom: 0.5rem;
//   font-weight: 500;
//   line-height: 1.2;
// }


@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}


@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}


@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}


@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}



//character styles

h1, .h1 {
  font-weight: $font-weight-300;
  font-size: $font-size-48;
  line-height: $line-spacing-58;
  color: $quixant-grey-text;
}

h2, .h2 {
  font-weight: $font-weight-300 !important;
  font-size: $font-size-32 !important;
  line-height: $line-spacing-32 !important;
  color: $quixant-grey-text;
}

h3, .h3 {
  font-weight: $font-weight-300 !important;
  font-size: $font-size-24 !important;
  line-height: $line-spacing-18-891828536987305 !important;
  color: $quixant-grey-text;
}

h4, .h4 {
  font-weight: $font-weight-medium;
  font-size: $font-size-20;
  line-height: $line-spacing-24;
  color: $quixant-grey-text;
}

h5, .h5 {
  font-weight: $font-weight-300;
  font-size: $font-size-16;
  line-height: $line-spacing-20;
  color:$quixant-grey-text;
}

h6, .h6 {
  font-weight: $font-weight-normal;
  font-size: $font-size-14 !important;
  line-height: $line-spacing-21-600000381469727;
  color: $quixant-grey-text;
}

.body-18px {
  font-weight: $font-weight-300;
  font-size: $font-size-18;
  line-height: $line-spacing-22;
  color: $quixant-grey-text;
}


// h1, .h1 {
//     font-size: $font-size-h1;
//     line-height: 1.15em;
// }
// h2, .h2{
//     font-size: $font-size-h2;
// }
// h3, .h3{
//     font-size: $font-size-h3;
//     line-height: 1.4em;
//     margin: 20px 0 10px;
// }
// h4, .h4{
//     font-size: $font-size-h4;
//     line-height: 1.4em;
//     font-weight: 300;
// }
// h5, .h5 {
//     font-size: $font-size-h5;
//     line-height: 1.4em;
//     margin-bottom: 15px;
// }
// h6, .h6{
//     font-size: $font-size-h6;
//     text-transform: uppercase;
//     font-weight: $font-weight-bold;
// }

.title,
.card-title,
.info-title,
.media .media-heading{
    //font-weight: $font-weight-extra-bold;
    // font-family: $font-family-serif;

    &,
    a{
        color: $black-color;
        text-decoration: none;
    }
}

.card-blog .card-title{
    font-weight: $font-weight-extra-bold;
}

h2.title{
    margin-bottom: $margin-base * 2;
}


.text-primary-color {
  color: $brand-primary !important;
}
.text-danger {
  color: $brand-danger !important;
}

.text-active {
  color: $active;
}

.text-inactive {
  color: $inactive;
}

.text-in-progress{
  color: $in-progress;
}

.text-white {
  color: $white;
}
.text-label {
  color: $brand-search-text-label !important;
}
.text-span {
  color: $brand-text !important;
}
.text-black {
  color: $black-color !important;
}
.table-border-top {
  border-top: 1px solid $brand-primary !important;
}

.pdf-th {
  background: $brand-profile;
  color: $white-color;
}

.pdfLogo{
  width: 180px !important;
}
.pdfOtherReqImg{
  width:180px !important;
  height: 180px !important;
}

.pdfTitle{
  align-items: center;
  text-align: center;
  color:#212529 !important;
  font-weight: 300 !important;
}

.pdfPara{
  color:#212529 !important;
}

.pdfTable td {
  border-bottom: 1px solid $brand-primary !important;
}

.pdfTable th {
  border-bottom: 1px solid $brand-primary !important;
}

.table-border-bottom {
  border-bottom: 1px solid $brand-primary !important;
}

.row-height {
  height: 42px !important;
}

.border {
  border: 1px solid $brand-search-text-label;
}

.border-none {
  border:none;
}

.margin-10 {
margin: 10px;
}

.padding-10 {
  padding: 10px;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-bottom-10{
  margin-bottom: 10px;
}

.margin-left-10{
  margin-left: 10px;
}

.padding-left-10{
  padding-left: 10px;
}

.margin-right-10{
  margin-right: 10px;
}

.margin-right-20{
  margin-right: 20px;
}

.background-none {
  background: none !important ;
}


.text-center {
  align-items: center;
  text-align: center;
}


.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.font-size-16 {
 font-size: $font-size-16;
}

.toasterSuccessClass {
  background-color: $brand-primary;
}
.word-break {
  word-break: break-all;
    white-space: normal;
}
.w-100 {
  width: 100%
}

.overflow-hidden {
  overflow-x: hidden ;
}

