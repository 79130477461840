@use "sass:math";

.dropdown-menu {
  min-width: 317px  !important;
  max-width: 460px;
  display: none;
  padding:0 !important;
  border: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  top:84px;
  // transition: transform $bmd-menu-expand-duration $bmd-animation-curve-default,
  //             opacity $bmd-menu-fade-duration $bmd-animation-curve-default;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

  &.showing {
    animation-name: bmd-dropdown-animation;
    animation-duration: $bmd-menu-expand-duration;
    animation-fill-mode: forwards;
    animation-timing-function: $bmd-animation-curve-default;
  }

  .open > &,
  &.show {
    display: block;
    opacity: 1;
    transform: scale(1);
  }

  &.hiding {
    display: block;
    opacity: 0;
    transform: scale(0);
  }

  &[x-placement="bottom-start"] {
    transform-origin: 0 0;
  }

  &[x-placement="bottom-end"] {
    transform-origin: 100% 0;
  }

  &[x-placement="top-start"] {
    transform-origin: 0 100%;
  }

  &[x-placement="top-end"] {
    transform-origin: 100% 100%;
  }


  .dropdown-item,
  li {    // used to properly size the ripple container
    position: relative;
    width: auto;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    color: $brand-text;
    font-weight: normal;
    text-decoration: none;
    padding: 9px 0;
    margin: 0 0 3px 0;
    font-size: .8125rem;
    border-radius: math.div($border-radius, 2);
    @include transitions($fast-transition-time, $transition-linear);
    
    min-width: $bmd-menu-item-min-width;
    overflow: hidden;
    line-height: $bmd-line-height;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  a:hover,
  a:focus,
  a:active {
    @include shadow-small-color($brand-primary);
    background-color: $brand-primary;
    color: #FFFFFF;
  }
}

// this could be in a .btn-group or .dropdown
.dropdown-toggle {
  &.bmd-btn-icon,
  &.bmd-btn-fab {
    // remove the dropdown icon
    &::after {
      display: none;
    }

    ~ .dropdown-menu {
      &.dropdown-menu-top-left,
      &.dropdown-menu-top-right {
        bottom: 2rem; // push up the bottom of the menu the height of the button
      }
    }
  }

  &:after{
      will-change: transform;
      transition: transform $bmd-animation-dropdown-caret linear;
  }

  .show &:after{
    @include rotate-180();
  }

  &.bmd-btn-fab-sm {
    ~ .dropdown-menu {
      &.dropdown-menu-top-left,
      &.dropdown-menu-top-right {
        bottom: 2.5rem // push up the bottom of the menu the height of the button
      }
    }
  }

  &.bmd-btn-icon {
    ~ .dropdown-menu {
      // collapse some spacing
      margin: 0;
    }
  }
}

@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown-menu.bootstrap-datetimepicker-widget{
    opacity: 0;
    transform: scale(0);
    transition-duration: $bmd-menu-expand-duration;
    transition-timing-function: $bmd-animation-curve-default;
    transform-origin: 0 0;
    will-change: transform, opacity;
    top: 0;
}

.dropdown-menu.bootstrap-datetimepicker-widget.top{
    transform-origin: 0 100%;
}

.dropdown-menu.bootstrap-datetimepicker-widget.open{
    opacity: 1;
    transform: scale(1);
    top: 0;
}

.noti-title {
 background-color: $brand-text-box !important;
}
.noti-title:hover {
  box-shadow: none;
}

.dropdown-profile {
  top: 100px !important
}