
@import "variables/colors";
@import "variables/shadow";

@import "variables/bootstrap-material-design-base";

// Customized BS variables
// @import "variables/brand";
@import "variables/card";
@import "variables/type";
@import '~@angular/material/theming';


$app-primary: mat-palette((
  50 : #fff2e1,
  100 : #ffddb4,
  200 : #fec785,
  300 : #fdb157,
  400 : #fca038,
  500 : #fb9126,
  600 : #f68524,
  700 : #EC6607,
  800 : #FF6600,
  900 : #dd4e1f,
  A100 : #f5f5f5,
  A200 : #ededed,
  A400 : #bcbcbc,
  A700 : #606060,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
));
$app-accent: mat-palette((
  50 : #f9f8f7,
  100 : #f1f0ef,
  200 : #e7e6e4,
  300 : #BDBDBD,
  400 : #F7F7F7,
  500 : #7F7F7E,
  600 : #737070,
  700 : #565554,
  800 : #2E2D2D,
  900 : #171616,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
));

$theming-material-components-primary: $app-primary;
$theming-material-components-accent: $app-accent;
$theming-material-components-warn: mat-palette($mat-red);

$brand-primary:              mat-color($theming-material-components-primary, 900);
$brand-profile:              mat-color($theming-material-components-primary, 800);
$brand-button-disabled:              mat-color($theming-material-components-primary, 700);
$brand-accent:               mat-color($theming-material-components-accent, 700);
$brand-background:         #1F1E1E;
$brand-danger:               mat-color($theming-material-components-warn);
$brand-text:                 mat-color($theming-material-components-accent, 400);
$brand-text-box:             mat-color($theming-material-components-accent, 800);
$brand-text-color:             mat-color($theming-material-components-accent,300);
$brand-search-text-box:      mat-color($theming-material-components-accent, 600);
$brand-search-text-label:      mat-color($theming-material-components-accent, 500);
$brand-text-background:      mat-color($theming-material-components-accent, 900);
$quixant-grey-text: #EFEFED;

$pot-table-header-size: 18px;
$mat-tooltip-size: 16px;
$table-padding: 27px;
$enable-flex: true; 
$enable-shadows: true;
$sidebar-width:              calc(100% - 180px) !default;
$sidebar-mini-width:         calc(100% - 80px) !default;

$sidebar-height:   calc(100% - 130px) !default;
$main-panel-height:   calc(100% - 130px) !default;

$black: #000;
$white: #fff;
$black-color: #3C4858 !default;
$black: #000000; $rgb-black: "0,0,0" !default;
$white: #ffffff; $rgb-white: "255,255,255" !default;

$active: #89f44a;
$inactive:  #E63324;
$in-progress: #FFAF7A;



@import "variables/bootstrap-material-design";
