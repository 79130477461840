
@import "~@angular/material/theming";

@include mat-core();
@import 'assets/scss/material-dashboard.scss';
@import 'assets/scss/theme.scss';


$app-primary: mat-palette((
  50 : #fff2e1,
  100 : #ffddb4,
  200 : #fec785,
  300 : #fdb157,
  400 : #fca038,
  500 : #e86721,
  600 : #f68524,
  700 : #ef7623,
  800 : #e86721,
  900 : #dd4e1f,
  A100 : #f5f5f5,
  A200 : #ededed,
  A400 : #bcbcbc,
  A700 : #606060,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
));
$app-accent: mat-palette((
  50 : #f9f8f7,
  100 : #f1f0ef,
  200 : #e7e6e4,
  300 : #d6d5d4,
  400 : #b2b0af,
  500 : #565554,
  600 : #696867,
  700 : #565554,
  800 : #383736,
  900 : #181716,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
));

$theming-material-components-primary: $app-primary;
$theming-material-components-accent: $app-accent;
$theming-material-components-warn: mat-palette($mat-red);


$theming-material-components-theme: mat-light-theme(
  $theming-material-components-primary,
  $theming-material-components-accent,
  $theming-material-components-warn
);


@include angular-material-theme($theming-material-components-theme);
.dark-theme {
  @include angular-material-color($dark-theme);
}

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($typography);


html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Proxima Nova Light";
  color: #FFFFFF !important;
}

.toasterSuccessClass {
  background:#89F44A
}

.toasterErrorClass {
  background:#FF5648
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $brand-accent;
}

.search-text-box {
  background: $brand-background;
  color: #fff !important;
  width: 100%;
  height: 36px;
  border: 1px solid $brand-accent;
  margin-top: 1px;
  margin-left: 1px;
  overflow-x: hidden !important;
}


.notification-box {
  box-shadow: 0px 0px 10px #0000001F;
  border: 1px solid $brand-text-box;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 10px;
}

.error-content {
  background-color: $brand-background;
  height: 100%;
  width: 100%;
  padding: 30px;
  .error-content-box {
    position: absolute;
    top: 35%;
    left: 40%;

    .error-logo-img {
      img {
       width: 260px;
       top: 5px;
      }
     }
  }
  
}


.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 6px;
  width: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aaa; }

  .scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 25px;
    }

