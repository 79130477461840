@use "sass:math";


$icon-color: #000 !default;
$margin-base:               1.071rem !default;
$font-color:                 #66615b !default;
$black-color:           #000000 !default;
$white-color: #fff !default;
$navbar-color: #555 !default;
$link-color: $brand-primary;
$white-transparent: rgba($white-color, .8);

// Background colors
$bg-primary: $brand-primary;
$bg-danger: $brand-danger;

//Paddings
$padding-general-y: 0.625rem !default;
$padding-general-x: 0.9375rem !default;
$padding-card-body-y: 0.9375rem !default;
$padding-card-body-x: 1.875rem !default;

// Buttons:
$mdb-btn-font-size-base: 12px !default;
$mdb-btn-icon-size: 32px !default;
$mdb-btn-icon-size-mini: 17px !default;

$bmd-line-height: 1.42857143 !default;
$btn-lg-line-height: 1.3333333 !default;

//Font-weight
$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold:    500 !default;
$font-weight-extra-bold: 700 !default;
$font-weight-ultra-bold: 900 !default;

//Border-radius
$border-radius-base: 3px !default;
$border-radius-small: 2px !default;
$border-radius-large: 6px !default;
$border-radius-huge: 10px !default;
$border-radius-label: 12px !default;
$border-radius-extreme: 30px !default;

// Animations
$bmd-animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$bmd-animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$bmd-animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$bmd-animation-curve-default: $bmd-animation-curve-fast-out-slow-in !default;
$bmd-animation-dropdown-caret: 150ms !default;
$general-transition-time:  300ms !default;

$slow-transition-time:           370ms !default;
$fast-transition-time:           150ms !default;

$transition-linear:                                   linear !default;
$transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-bezier-rotating-card:         cubic-bezier(0.34, 1.45, 0.7, 1) !default;
$transition-ease:           ease 0s;


$bmd-menu-item-min-width: 7rem !default; 
$bmd-menu-expand-duration: 0.3s !default;
$bmd-menu-fade-duration: 0.2s !default;
