.navbar {
  top: 0px;
  left: 0px;
  height: 130px;
  background: #1F1E1E 0% 0% no-repeat padding-box;
  opacity: 1;

    &.fixed-top{
        border-radius: 0;
    }

    .navbar-nav{
        .nav-item .nav-link{
            position: relative;
            color: inherit;
            padding: $padding-general-x;
            font-weight: $font-weight-default;
            font-size: $mdb-btn-font-size-base;
            text-transform: uppercase;
            border-radius: $border-radius-base;
            line-height: 20px;

            &:not(.btn-just-icon) .fa{
                position: relative;
                top: 2px;
                margin-top: -4px;
                margin-right: 4px;
            }

            & .material-icons,
            & .fa{
                font-size: $font-size-lg;
                max-width: 24px;
                margin-top: -1.1em;
            }

            &:not(.btn) .material-icons{
                margin-top: -7px;
                top: 3px;
                position: relative;
                margin-right: 3px;
            }

            
        }

        .dropdown-menu-right{
            transform-origin: 100% 0;
            background-color: $brand-background;
        }

        .nav-item.active .nav-link{
            &,
            &:hover,
            &:focus {
              color: inherit;
              background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .btn,
    .navbar-nav .nav-item .btn{
        margin-top: 0;
        margin-bottom: 0;
    }
    .navbar-nav .nav-item .profile{
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1.5px solid $brand-primary;
      font-size: 18px;
      background: $brand-profile;
    }

    .navbar-toggler{
        cursor: pointer;
        outline: 0;

        .navbar-toggler-icon{
            width: 22px;
            height: 2px;
            vertical-align: middle;
            outline: 0;
            display: block;
            border-radius: 1px;

            & + .navbar-toggler-icon{
                margin-top: 4px;
            }
        }
    }

    .navbar-wrapper{
        display: inline-flex;
        align-items: center;
    }

    // give correct size to ripple container
    .navbar-brand {
        position: relative;
        color: inherit;
        height: 50px;
        font-size: $navbar-brand-font-size - 0.125;
        line-height: 30px;
        padding: $padding-general-y 0;
        font-weight: 300;
        margin-left: 1rem;
    }

    > .container {
        flex: 1;
    }

    // &.bg-primary{
    //     @include navbar-colors($bg-primary, $white-color);
    // }

    // &.bg-danger{
    //     @include navbar-colors($bg-danger, $white-color);
    // }

    // &.navbar-transparent{
    //     // background-color: transparent !important;
    //     // box-shadow: none;
    //     // padding-top: 25px;

    // }

  .notification{
    position: absolute;
    top: 5px;
    border: 1px solid #FFF;
    right: 10px;
    font-size: 10px;
    font-weight:600;
    background: #f44336;
    color: #FFFFFF;
    min-width: 20px;
    padding: 0px 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
  }
}

.navbar{
  .navbar-minimize{
    padding: 3px 0 0 15px;
  }

  &.navbar-transparent{
    padding-top: 10px;
  }

  .collapse{
    .navbar-nav{
      .nav-item .nav-link{
        position: relative;
        padding: 10px 20px 10px 10px;
        font-weight: $font-weight-default;
        font-size: $mdb-btn-font-size-base;
        text-transform: uppercase;
        border-radius: $border-radius-base;
        line-height: 20px;
        margin-left: 5px;
        color: inherit;

          &:not(.btn-just-icon) .fa{
              position: relative;
              top: 2px;
              margin-top: -4px;
              margin-right: 4px;
          }

          & .material-icons,
          & .fa{
              font-size: $font-size-lg;
              max-width: 24px;
              margin-top: -1.1em;
              color: $brand-primary
          }

          &:not(.btn) .material-icons{
              top: 26px;
              position: relative;
              font-size: 30px;
          }
          .notification{
            top: 0;
          }
        }
    }
  }
}

.off-canvas-sidebar{
  .navbar{
    .navbar-collapse{
      .navbar-nav .nav-item{
        .nav-link{
          padding-top: 15px;
          padding-bottom: 15px;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          border-radius: 3px;
          color: $white-color;
          margin: 0 15px;

          &:hover{
            background: rgba(200, 200, 200, 0.2);
          }
        }
      }
    }
  }
}

.bell_img {
  background-image: url('/assets/svg/bell_ring_notification_alarm.svg');
background-color: black;
background-blend-mode: multiply;
width: 80% !important;
    height: 80% !important;
    padding-top: 55% !important;
    background-position: top !important;
    background-size: 70% !important;
    background-repeat: no-repeat !important;
}

.logo-img{
  top: 25px;
left: 32px;
width: 85px;
height: 31px;
opacity: 1;
margin-bottom: 10px;

  img{
    width: 180px;
      position: absolute;
  }
}

.nav-title {
  font-size: 48px !important;
  font-weight: 300 !important;
}


.margin-top-60 {
  margin-top: 60px;
}