
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: $border-radius-large;
  // color: $gray-dark;
  background: $white-color;
  width: 100%;
  z-index: -1;


  .form-check {
      margin-top: 15px;
  }

    .card-title{
        margin-top: 0.625rem;

        &:last-child{
            margin-bottom: 0;
        }
    }

  // Cards have a default elevation of 2dp.
  // @include box-shadow($bmd-shadow-2dp);
  @extend %std-font;


  &.no-shadow {
    .card-header-image,
    .card-header-image img {
      box-shadow: none !important;
      }
  }

  .card-body,
  .card-footer {
    padding: $padding-card-body-y $padding-card-body-x;
  }

  .card-body {
      & + .card-footer{
        padding-top: 0rem;
        border: 0;
        border-radius: $border-radius-large;
      }
  }

  @include media-breakpoint-up(lg) {
    // On desktop, cards can have a resting elevation of 0dp and gain an elevation of 8dp on hover.
    &.bmd-card-flat {
      box-shadow: none;
    }
  }

    .card-header {
      border-bottom: none;
      background: transparent;
        .title{
            color: $white-color;
        }

        &:not([class*="card-header-"]){
            // @include shadow-big();
        }

        .nav-tabs {
            padding: 0;
        }

        &.card-header-image {
            position: relative;
            padding: 0;
            z-index: 1;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: -30px;
            border-radius: $border-radius-large;

            img {
                width: 100%;
                border-radius: $border-radius-large;
                pointer-events: none;
                @include shadow-big-image();
            }
            .card-title {
                position: absolute;
                bottom: 15px;
                left: 15px;
                color: $white-color;
                font-size: $font-size-h4;
                text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
            }

            .colored-shadow{
                transform: scale(0.94);
                top: 12px;
                filter: blur(12px);
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                z-index: -1;
                transition: opacity .45s;
                opacity: 0;
            }

            &.no-shadow{
                box-shadow: none;

                &.shadow-normal{
                    @include shadow-big();
                }

                .colored-shadow{
                    display: none !important;
                }
            }
        }
    }

    .card-header-primary .card-icon,
    .card-header-primary:not(.card-header-icon):not(.card-header-text),
    .card-header-primary .card-text{
        @include shadow-big-color($brand-primary);
    }
    .card-header-danger .card-icon,
    .card-header-danger:not(.card-header-icon):not(.card-header-text),
    .card-header-danger .card-text{
        @include shadow-big-color($brand-danger);
    }

    

    [class*="card-header-"],
    &[class*="bg-"]{
        color: $white-color;

        .card-title a,
        .card-title,
        .icon i{
            color: $white-color;
        }

        .icon i{
            border-color: rgba(255, 255, 255, 0.25);
        }
        .author a,
        .stats,
        .card-description{
            color: $white-transparent;
        }

        .author a{
            &:hover,
            &:focus,
            &:active{
                color: $white-color;
            }
        }
    }

    .author{
        .avatar{
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 5px;
        }

        a{
            color: $black-color;
            text-decoration: none;

            .ripple-container{
                display: none;
            }
        }
    }

    &[class*="bg-"],
    &[class*="bg-"] .card-body{
        border-radius: $border-radius-large;

        h1,
        h2,
        h3{
          small{
              color: $white-transparent;
          }
        }
    }

}
.card-plain {
  background: transparent;
  box-shadow: none;

  .card-header:not(.card-avatar) {
      margin-left: 0;
      margin-right: 0;
  }

  .card-body {
      padding-left: 5px;
      padding-right: 5px;
  }

  .card-header-image {
      margin: 0 !important;
      border-radius: $border-radius-large;

      img {
        border-radius: $border-radius-large;
      }
  }
}


.card {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);

  .table tr:first-child td{
    border-top: none;
  }

  .card-title{
    margin-top: 0;
    margin-bottom: 15px;
  }

  .card-body{
    padding: $padding-card-body-y 20px;
    position: relative;

  }

  .card-header {
    z-index: 3 !important;

    .card-title{
      margin-bottom: 3px;
    }


    &.card-header-text {
        display: inline-block;

        &:after {
            content: "";
            display: table;
        }
    }

    &.card-header-icon,
    &.card-header-text {
        i {
            width: 33px;
            height: 33px;
            text-align: center;
            line-height: 33px;
        }
        .card-title{
          margin-top: 15px;
          color: $black-color;
        }
        h4{
          font-weight: 300;
        }
    }

    &.card-header-tabs {
        .nav-tabs {
            background: transparent;
            padding: 0;
        }
        .nav-tabs-title {
            float: left;
            padding: 10px 10px 10px 0;
            line-height: 24px;
        }
    }
  }

  &.card-plain {
    .card-header {
      &.card-header-icon + .card-body .card-title,
      &.card-header-icon + .card-body  {
          margin-top: -20px;
      }
    }
  }

  .card-actions {
      position: absolute;
      z-index: 1;
      top: -50px;
      width: calc(100% - 30px);
      left: 17px;
      right: 17px;
      text-align: center;

      .card-header{
        padding: 0;
        min-height: 160px;
      }

      .btn {
          padding-left: 12px;
          padding-right: 12px;
      }
      .fix-broken-card {
          position: absolute;
          top: -65px;
      }
  }

  .card-body + .card-footer,
  .card-footer{
    padding: 0;
    padding-top: 10px;
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;

    h6 {
      width: 100%;
    }

    .stats{
      color: #999999;
      font-size: 12px;
      line-height: 22px;

      .material-icons{
        position: relative;
        top: 4px;
        font-size: 16px;
      }
    }
  }
  [class*="card-header-"] {
      margin: 0px 15px 0;
      padding: 0;

      .card-title {
        color: rgba(255, 255, 255, 0.62);
        a {
          color: $white-color;
        }
      }

      &:not(.card-header-icon):not(.card-header-text):not(.card-header-image){
        border-radius: $border-radius-base;
        margin-top: -20px;
        padding: 15px;
      }

      .card-icon,
      .card-text{
        border-radius: $border-radius-base;
        // background-color: $gray-color;
        padding: 15px;
        margin-top: -20px;
        margin-right: 15px;
        float: left;
      }

      .card-text{
        float: none;
        display: inline-block;
        margin-right: 0;

        .card-title{
          color: $white-color;
          margin-top: 0;
        }
      }

      position: relative;
  }

  [data-header-animation="true"] {
      @include transform-translate-y(0);
      -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  &:hover {
      [data-header-animation="true"]{
          @include transform-translate-y(-50px);
      }
  }


  .card-body.table-full-width{
    padding: 0;
  }

  .card-plain .card-header-icon {
    margin-right: 15px !important;
  }
}