.mat-table {
    width: 100% !important;
    background: $brand-background !important;
    font-family: 'proxima-nova', sans-serif !important;
}
.table thead th {
  font-weight: normal !important;
}

.mat-header-cell {
    color: $brand-text !important;
}
.mat-cell {
    color: $brand-text !important;
    border-bottom-color: $brand-primary !important;
    padding-right: $table-padding !important;
}

th.mat-header-cell {
    text-align: left !important;
    border-bottom-color: $brand-primary !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
td.mat-cell{
  text-align: left !important;
}
.pot-table .mat-cell {
  color: $brand-text !important;
  border-bottom-color:  $brand-accent !important;
  padding-right: $table-padding !important;
  width: 25% !important;
}
.pot-table th.mat-header-cell {
  text-align: left !important;
  border-bottom-color: $brand-accent !important;
  font-size: $pot-table-header-size !important;
  width: 25% !important;
}


.mat-paginator {
    background: $brand-background !important;
}

.mat-tooltip{
  background: $brand-background !important;
  color: $brand-text !important;
  font-size: $mat-tooltip-size !important;
}
.mat-tooltip-show{
  word-wrap: break-word !important;
  white-space: normal !important;
}

.mat-hint{
  font-size: $mat-tooltip-size !important;
}

.mat-paginator-container {
  width: 98% !important;
}

.mat-paginator-icon {
  margin-bottom: 4px !important;
  color: $white;
}

.mat-paginator-page-size, .mat-paginator-range-actions {
    color: $white;
    .mat-select-arrow {
      color: $white;
    }
    .mat-form-field-flex{
      border-bottom: 1px solid #fff;
    }
}
.mat-paginator-range-label {
  // display: none;
}


.mat-standard-chip {
  margin-right: 10px;
}
.mat-dialog-title {
    color: $white-color !important;
}

.mat-dialog-container {
    background: $brand-background !important;
    padding: 18px 18px !important;
    overflow: hidden !important;
}

.mat-expansion-panel-header {
    background-color: $brand-background;
    width: 100%;
}
.mat-expansion-panel-header-title {
  font-size: 20px !important;
    font-weight: 600 !important;
}
.mat-expansion-indicator::after {
  color: white !important;
}

  .product-sku {
  .mat-expansion-panel-header {
    padding: 0 !important;
    min-height: 64px !important;
    height: auto !important;
  }
  .mat-expansion-indicator:after {
    margin: 54px 7px 9px 10px;
    padding: 5px;
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
    max-height: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important
  }
  .mat-expansion-panel-header.mat-expanded{
    height: auto !important;
    max-height:auto !important;
  }

  .mat-expansion-panel {
   height: auto !important;
  }
 
}

.create-pot-panel {
  .mat-expansion-panel-header {
    border-top: 1px solid #FF6600 !important;
    // border-bottom: 1px solid #FF6600 !important;
    border-radius: 0 !important;
  }
  .mat-expansion-indicator::after {
    padding: 4px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }
} 




.mat-expansion-panel {
  background-color:  $brand-background !important;
  box-shadow: none !important;
}
// .mat-stroked-button.mat-button-disabled.mat-button-disabled {  
//   border-color: $brand-primary !important;  
// }

.mat-raised-button,.mat-stroked-button, .mat-button {
  min-width: 151px !important;
  height: 44px !important;
}


.disabled-raised-button {
    background-color: $brand-button-disabled !important;
}



 .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: $brand-text-background;
    height: 52px;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 8px 0 !important;
  font-size: 16px;
}
.search-box.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 8px 16px !important;
  font-size: 16px;
}
.filter-search-box .mat-form-field-appearance-outline .mat-form-field-outline {
  height: 40px;
  background-color: $brand-text-box;
  border-radius: 4px;
}
  

.filter-search-box .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 2px 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border:none;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-header-pagination-chevron {
  border-color:$white-color
}

.faded-tab .mat-tab-label {
  background: $brand-text-box;
  min-width: 96px !important;
  margin-right: 10px !important;
  color: $white-color;
}
.faded-tab .mat-tab-label-active {
  background: transparent linear-gradient(90deg, #FF6600 0%, #91430F82 58%, #1F1E1E00 100%) 0% 0% no-repeat !important;
  min-width: 96px !important;
  margin-right: 10px !important;
  color: $white-color;
}
.grey-tab .mat-tab-label {
  border-radius: 2px 2px 0px 0px;
    opacity: 1;
    height: 38px;
    padding: 8px;
    margin-bottom: 6px;
}

.grey-tab .mat-tab-label-active {
  background: $brand-accent 0% 0% no-repeat padding-box !important;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
  height: 38px;
  padding: 8px;
  // width: 106px;
}

.grey-tab .mat-tab-label-container {
  border-bottom: 1px solid $brand-accent;
}

.faded-tab .mat-tab-label.mat-tab-disabled {
  background: none;
  .mat-icon-button, .mat-icon {
    height: 32px;
    width: 32px;
    color: white !important;
    margin-bottom: 5px;
    margin-right: 16px;
  }
}

.faded-tab .mat-tab-header {
  border: none !important;
}

.no-style-tab  .mat-tab-label.mat-tab-label-active {
  color: #FF6600;
}

.no-style-tab .mat-tab-label {
  color: $brand-search-text-label;
}

mat-icon.mat-24 {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

mat-icon.mat-20 {
  font-size: 20px;
  height: 20px;
  width: 20px;
}


mat-icon.mat-18 {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
  background-color:unset !important;
}

.mat-tab-header-pagination-chevron{
  border-color: white !important;
}


.dropdown-box .mat-form-field-appearance-outline .mat-form-field-outline  {
    background-color: $brand-text-box;
  }
  .pot-dropdown-box {
    height: 40px !important;
  }
  .pot-dropdown-box .mat-form-field-appearance-outline .mat-form-field-outline  {
    background-color: $brand-text-box;
    height: 40px;
  }
  .pot-input-box {
    height: 40px !important;
  }
  .pot-input-box .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 4px 0 !important;
  }

  .pot-input-box .mat-form-field-label-wrapper{
    bottom: 0 !important;
    padding-bottom: 22px !important;
    top: auto !important;
    padding-top: inherit !important;
  }

  .pot-input-box .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 40px;
  }
  .empty-box {
    min-height: 40px;
    background-color: $brand-text-box;
  }

.select-dropdown .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
    padding-left:10px !important;
    padding-right: 0px !important;
  }

.cdk-overlay-pane .filter-select-panel{
  top:32px !important;
  position: absolute;
}

.cdk-overlay-container .cdk-overlay-pane .filter-select-panel{
        // margin-top: 32px !important;
        margin-left: 28px !important;
        margin-bottom: 32px !important;
    }

.cdk-overlay-container .cdk-overlay-pane .family-select-panel{
  margin-top: 35px !important;
  margin-left: 3px !important;
  margin-bottom: 35px !important;
}
.cdk-overlay-container .cdk-overlay-pane .section-select-dropdown {
  margin-top: 23px !important;
  margin-left: 4px !important;
  margin-bottom: 31px !important;
}

.cdk-overlay-container .cdk-overlay-pane .type-select-dropdown {
  margin-top: 34px !important;
  margin-left: 4px !important;
  margin-bottom: 31px !important;
}

.cdk-overlay-container .cdk-overlay-pane .hardware-select-dropdown {
  margin-top: 34px !important;
  margin-left: 4px !important;
  margin-bottom: 34px !important;
}

.cdk-overlay-container .cdk-overlay-pane .user-select-panel{
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.cdk-overlay-container .cdk-overlay-pane .owner-select-panel{
  margin-top: 37px !important;
  margin-left: 28px !important;
  // margin-bottom: 30px !important;
}

.cdk-overlay-container .cdk-overlay-pane .customer-select-panel{
  margin-top: 34px !important;
  margin-left: 5px !important;
  margin-bottom: 35px !important;
}
  .mat-autocomplete-panel, .mat-option-text {
    background-color: $brand-text-box !important;
  }
.input-box .mat-form-field {
      width: 141px !important;
  }
  .input-box .mat-form-field-appearance-outline .mat-form-field-outline {
    height: auto !important;
  }
  .input-box .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 4px 0;
    font-size: 14px;
    width: auto !important;
  }
  .input-box .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 0;
  }


  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mat-horizontal-stepper-wrapper {
    background-color: $brand-background !important;
  }

  .mat-horizontal-stepper-header-container {
    width: fit-content;
    margin: 0 auto;
    border: 1px solid $brand-text-box;
    padding: 0 150px;
  }

  .mat-step-header .mat-step-label{
    color: $brand-search-text-label !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-active {
    color: $white-color !important;
  }

  .mat-step-header .mat-step-icon, .mat-stepper-horizontal-line {
    background-color: $brand-search-text-label !important;
  }
   .mat-horizontal-stepper-header{
    pointer-events: none !important;
}

.mat-input-element {
    color: $brand-text !important;
}
  
  .mat-pseudo-checkbox, .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    color: $white-color !important;
  }
  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    border-color: $white-color !important;
  }

  .mat-checkbox-background{
    border-color:$white-color !important;
    background-color: transparent !important; 
  }

  .mat-checkbox-frame  {
    border-color:$brand-text !important;
  }

  .mat-option-text, button.mat-menu-item, .mat-select-value, .mat-select-placeholder,.mat-form-field-appearance-outline .mat-form-field-label  {
    color:$brand-text !important;
    text-overflow: unset !important;
  }

  .mat-select-arrow {
    margin: 10px 10px 0 14px;
    color: white;
  }

  .mat-checkbox-frame {
    color: $brand-primary;
  }

  .mat-input-element::placeholder {
    color: $brand-search-text-box !important
  }
  
  .mat-select-panel, .mat-menu-panel, .mat-pseudo-checkbox {
    background-color: $brand-text-box !important;
  }

  .mat-select-panel {
    max-height: 298px !important;
  }

  .cdk-overlay-container .cdk-overlay-pane .input-mat-box{
    overflow-y: scroll;
    overflow: hidden !important;
}

  .toggle-action-button {
    // border-bottom: 1.5px solid $brand-background !important;
   }

  .mat-label-text {
    color: $brand-search-text-label;
  }

  .mat-slide-toggle{
    color: $brand-search-text-label;
  }
  .mat-radio-outer-circle {
    border-color: $brand-primary !important;
  }

  .mat-radio-label-content {
    color: $brand-text;
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }

  .filter-box {
    .mat-form-field-wrapper {
      max-width: 300px !important;
      text-align: left !important;
      height: 40px !important;
      padding: 0 !important;
      margin: 0.25em 0.25em 0.25em 0  !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0 0 !important;
      font-size: 16px;
      overflow: hidden !important;
      width: fit-content !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border-radius: 0;
        border: none;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border-radius: 0;
        border: none;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
       
        background-color: $brand-text-box;
        color: $white-color;
        border: none;
        height: 40px;
        border-radius: 4px;
      }
      .mat-select-placeholder {
        color: $brand-text
      }
      .mat-select-arrow {
        color: $brand-text;
        margin-top: 4px;
      }

      .table thead th {
        color: $white-color !important;
        font-size: 14px;
        border-bottom: 2px solid $brand-primary !important;
        font-weight: 500 !important;
      }

      .table tbody tr {
        border-bottom: 2px solid $brand-primary !important;
      }

      

      table th {
        border-top: none !important;
        width: auto !important;
    }
    
  } 
  .text-area-sku {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      height: auto !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 4px 0;
      font-size: 14px;
      width: auto !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      padding: 0;
    }
  }
  

  .text-area {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      height: auto !important;
    }
  }

  .pot-button-container {
    height: 96px;
    background: $brand-text-box;
    text-align: end;
}

.custom-bread-crumb{
  padding: 0 !important;
  font-size: 18px !important;
  color: $brand-accent !important;
  a:first-child {
    font-size: 48px;
    color: $white !important;
  }
}

.input-search-box {
    border-bottom: 1px solid $brand-search-text-label !important;
    height: 25px !important;
    margin-top: 5px !important;
    margin-left: 1px !important;
    padding: 0px 10px;
}


@media screen and (min-width: 1400px) {
  .input-box .mat-form-field {
    width: 100% !important;
  }
}
@media screen and (min-width: 1600px) {
  .input-box .mat-form-field {
    width: 100% !important;
  }
}
@media screen and (min-width: 1900px) {
  .input-box .mat-form-field {
    width: 100% !important;
  }
}

@media screen and (min-width: 1400px) {
  .revision-size {
    max-width: 15% !important;
  }
  .no-image-box {
    max-width: 15% !important;
  }
  .image-box-size {
    max-width: 25% !important;
  }
}
@media screen and (min-width: 1600px) {
  .revision-size {
    max-width: 15% !important;
  }
  .no-image-box {
    max-width: 15% !important;
  }
  .image-box-size {
    max-width: 22% !important;
  }
}
@media screen and (min-width: 1900px) {
  .revision-size {
    max-width: 15% !important;

    .no-image-box {
      max-width: 15% !important;
    }
    .image-box-size {
      max-width: 22% !important;
    }
  }
}