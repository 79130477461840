
$bmd-font-weight-base: 400;
$border-radius-base: 3px !default;
$border-radius-small: 2px !default;
$border-radius-large: 6px !default;
$border-radius-huge: 10px !default;
$border-radius-label: 12px !default;
$border-radius-extreme: 30px !default;

// Typography elements
$mdb-font-family: 'Proxima Nova Light' !default;
$icon-color: #000 !default;