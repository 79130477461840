@use "sass:math";


$font-family-sans-serif: 'Proxima Nova', sans-serif;;
$font-family-serif:      'Proxima Nova', sans-serif;;
$font-size-base:             1rem !default;

$font-size-h1:                 3.3125rem;
$font-size-h2:                 2.25rem !default;
$font-size-h3:                 1.5625rem;
$font-size-h4:                 1.125rem !default;
$font-size-h5:                 1.0625rem !default;
$font-size-h6:                 0.75rem !default;
$font-paragraph:               14px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;


$display1-size: 7rem !default; 
$display2-size: 3.5rem !default;
$display3-size: 2.8125rem !default; 
$display4-size: 2.125rem !default; 
$headings-font-weight:         400 !default; 

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold:    500 !default;
$font-weight-extra-bold: 700 !default;

$font-size-large:              1em          !default;
$font-size-large-navbar:       20px         !default;

// Animations
$bmd-animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$bmd-animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$bmd-animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$bmd-animation-curve-default: $bmd-animation-curve-fast-out-slow-in !default;
$bmd-animation-dropdown-caret: 150ms !default;
$general-transition-time:  300ms !default;

$slow-transition-time:           370ms !default;
$fast-transition-time:           150ms !default;

$transition-linear:                                   linear !default;
$transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-bezier-rotating-card:         cubic-bezier(0.34, 1.45, 0.7, 1) !default;
$font-family-proxima-nova: 'Proxima Nova Regular';
$font-family-proximanova-medium: 'Proxima Nova Medium';
$font-style-normal: normal;
$font-weight-300: 300;
$font-weight-medium: medium;
$font-weight-normal: normal;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-48: 48px;
$character-spacing-0: 0px;
$line-spacing-20: 20px;
$line-spacing-22: 22px;
$line-spacing-24: 24px;
$line-spacing-32: 32px;
$line-spacing-58: 58px;
$line-spacing-18-891828536987305: 18.891828536987305px;
$line-spacing-21-600000381469727: 21.600000381469727px;

