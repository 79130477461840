
.serif-font{
    font-family: $font-family-serif;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}

a{
  color: $link-color;
  &:hover,
  &:focus{
      color: darken($link-color, 5%);
      text-decoration: none;
  }

  & .material-icons {
    vertical-align: middle;
  }
}

/*           Animations              */
.animation-transition-general{
    @include transition-all($general-transition-time, $transition-linear);
}

.animation-transition-slow{
    @include transition-all($slow-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition-all($fast-transition-time, $transition-ease);
}

.caret,
.sidebar a{
    @include transition-all($fast-transition-time, ease-in);
}
